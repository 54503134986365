<template>
  <c-box
    w="100%"
    max-width="1500px"
    mx="auto"
    bg="#FFF"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['16px', '30px']"
    :min-height="['unset', '74vh']"  
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        padding="20px 90px"
        :paths="breadcrumbPath"
      />
    </Portal>

    <DownloadMealPlan
      :type="['review', 'revisi', 'sent', 'read'].includes(selectedListClientMealPlanDetail?.status) ? 'preview-with-download' : !!selectedListClientMealPlanDetail?.status ?'preview' : ''"
      :program-id-local="$route.params?.programId"
      :month="$route.params?.month"
      :client-id-local="$route.params?.clientId"
    />

    <c-flex
      flex-direction="column"
      width="100%"
      gap="16px"
    >
      <c-flex
        w="100%"
        gap="20px"
        align-items="center"
      >
        <c-image
          :w="['80px']"
          :h="['80px']"
          object-fit="cover"
          border-radius="80px"
          :src="getPhotoUser(client?.photoUrl)"
        />
        <c-box w="100%">
          <c-text
            :font-size="['18px']"
            font-weight="500"
            color="neutral.black"
            margin-bottom="8px"
          >
            {{ client?.firstName ?? '...' }} {{ client?.lastName }}
          </c-text>
          <c-text
            :font-size="['14px']"
            font-weight="400"
            color="primary.400"
          >
            {{ client?.phone ?? '...' }}
          </c-text>
        </c-box>
      </c-flex>
      <c-flex
        width="100%"
        justify-content="space-between"
        align-items="center"
      >
        <c-button
          variant-color="primary"
          border-radius="30px"
          font-size="18px"
          padding="0px 20px"
          font-weight="600"
          :is-disabled="!client?.id"
          @click="onOpenQuestionnaire"
        >
          Formulir Gizi
        </c-button>

        <c-menu
          v-slot="{ isOpen }"
          :auto-select="false"
        >
          <c-menu-button
            variant="outline"
            variant-color="primary"
            font-weight="500"
            font-size="18px"
            gap="14px"
            padding-inline="20px"
            border-radius="40px"
          >
            Panduan Makan Bulan ke-{{ $route.params?.month || 'x' }}
            <c-box
              width="14px"
              height="14px"
              :transform="isOpen ? 'rotate(180deg)' : 'rotate(0deg)'"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-arrow-down.svg')"
                height="100%"
                width="100%"
                fill="#008C81"
              />
            </c-box>
          </c-menu-button>
          <c-menu-list
            padding="0px"
          >
            <c-menu-item
              v-for="(opt) in optionListClientMealPlan"
              :key="opt.month"
              padding="8px 16px"
              font-weight="500"
              font-size="18px"
              :background-color="opt.month === month ? 'primary.50' : 'neutral.white'"
              :is-disabled="opt.isDisabled"
              @click="onChangeMonth(opt)"
            >
              Panduan Makan Bulan ke-{{ opt.month }}
            </c-menu-item>
          </c-menu-list>
        </c-menu>
      </c-flex>
    </c-flex>

    <c-box
      margin-top="16px"
      width="100%"
    >
      <c-flex
        background-color="#F3B965"
        padding="8px 16px"
        justify-content="space-between"
        align-items="center"
        border-top-left-radius="8px"
        border-top-right-radius="8px"
      >
        <c-text
          font-size="18px"
          font-weight="500"
        >
          Status Panduan Makan
        </c-text>
        <c-text
          font-size="16px"
          font-weight="400"
          text-transform="capitalize"
        >
          {{ selectedListClientMealPlanDetail?.status || '-' }}
        </c-text>
      </c-flex>
      <c-grid
        height="100%"
        width="100%"
        min-width="600px"
        template-columns="auto 1fr"
        padding="12px 32px"
        background-color="#FFFCDD"
      >
        <c-grid-item
          border-bottom="1px solid #C4C4C4"
          display="flex"
          align-items="center"
          padding="16px 0"
        >
          <c-text
            font-weight="400"
            font-size="16px"
          >
            Client Manager
          </c-text>
        </c-grid-item>
        <c-grid-item
          border-bottom="1px solid #C4C4C4"
          display="flex"
          align-items="center"
          padding="16px 0 16px 40px"
        >
          <BaseInputAutocomplete
            v-model="selectedListClientMealPlanDetail.clientManagerId"
            placeholder="Pilih Client Manager"
            full-width
            is-without-margin-bottom
            is-required
            :options="optionListClientManager"
            :is-disabled="
              ['review','sent', 'read'].includes(selectedListClientMealPlanDetail?.status)
            "
          />
        </c-grid-item>
        <c-grid-item
          border-bottom="1px solid #C4C4C4"
          display="flex"
          align-items="center"
          padding="16px 0"
        >
          <c-text
            font-weight="400"
            font-size="16px"
          >
            Terakhir Dikirim
          </c-text>
        </c-grid-item>
        <c-grid-item
          border-bottom="1px solid #C4C4C4"
          padding-left="16px"
          display="flex"
          align-items="center"
          padding="16px 0 16px 40px"
        >
          <c-text>
            {{ selectedListClientMealPlanDetail?.createdAt ? formatDateV2(
              selectedListClientMealPlanDetail?.createdAt,
              7
            ) : '-' }}
          </c-text>
        </c-grid-item>
        <c-grid-item
          border-bottom="1px solid #C4C4C4"
          display="flex"
          padding="16px 0"
        >
          <c-text
            font-weight="400"
            font-size="16px"
          >
            Catatan Client Manager
          </c-text>
        </c-grid-item>

        <c-grid-item
          border-bottom="1px solid #C4C4C4"
          display="flex"
          align-items="center"
          padding="16px 0 16px 40px"
          transition="all 1s"
          overflow="hidden"
        >
          <c-text
            ref="ooa12"
            width="100%"
            class="line-clamp-1"
          >
            <p v-html="selectedListClientMealPlanDetail?.noteFromClientManager || '-'" />
          </c-text>
          <c-button
            v-if="selectedListClientMealPlanDetail?.noteFromClientManager"
            variant="link"
            display="contents"
            font-weight="400"
            color="primary.400"
            @click="onOpenNoteList('clientManager')"
          >
            Selengkapnya
          </c-button>
        </c-grid-item>
        
        <c-grid-item
          border-bottom="1px solid #C4C4C4"
          display="flex"
          padding="16px 0"
        >
          <c-text
            font-weight="400"
            font-size="16px"
          >
            Catatan Ahli Gizi
          </c-text>
        </c-grid-item>

        <c-grid-item
          border-bottom="1px solid #C4C4C4"
          display="flex"
          align-items="center"
          padding="16px 0 16px 40px"
          transition="all 1s"
          overflow="hidden"
        >
          <c-text
            width="100%"
            class="line-clamp-1"
          >
            <p v-html="selectedListClientMealPlanDetail?.noteFromNutritionist || '-'" />
          </c-text>
          <c-button
            v-if="selectedListClientMealPlanDetail?.noteFromNutritionist"
            variant="link"
            display="contents"
            font-weight="400"
            color="primary.400"
            @click="onOpenNoteList('nutritionist')"
          >
            Selengkapnya
          </c-button>
        </c-grid-item>
      </c-grid>

      <c-flex
        v-if="['revision', 'revisi', 'draft'].includes(selectedListClientMealPlanDetail?.status)"
        background-color="#FFFCDD"
        align-items="center"
        flex-direction="column"
      >
        <c-box padding-top="3px">
          <c-text
            :font-size="['15px', '18px']"
            font-weight="500"
          >
            Catatan Ahli Gizi
          </c-text>
        </c-box>
        <c-box
          padding-top="8px"
          padding-inline="16px"
          width="100%"
        >
          <BaseEditor
            v-model="note"
            image-provider-url="/v1/nutritionist/meal-plans/upload"
          />
        </c-box>
      </c-flex>

      <c-flex
        gap="8px"
        justify-content="flex-end"
        background-color="#FFFCDD"
        padding="12px 32px"
        border-bottom-left-radius="8px"
        border-bottom-right-radius="8px"
      >
        <ModalDuplicateMealPlan
          :id="selectedListClientMealPlanDetail?.id"
          :disabled="!['sent', 'read'].includes(selectedListClientMealPlanDetail?.status)"
          :list-month="optionListClientMealPlan"
        />
        <c-button
          variant-color="primary"
          font-size="18px"
          font-weight="500"
          border-radius="calc(infinity * 1px)"
          width="160px"
          size="lg"
          :disabled="selectedListClientMealPlanDetail?.status === 'review'
            || selectedListClientMealPlanDetail?.status === 'sent'
            || selectedListClientMealPlanDetail?.validation !== 1
            || !selectedListClientMealPlanDetail.clientManagerId
            || !isValidOnEdit"
          @click="isOpenModalSendToClientManager = true"
        >
          Kirim
        </c-button>
      </c-flex>
    </c-box>

    <router-view
      :is-edited="isEdited"
      :is-program-consultation-basic="isProgramConsultationBasic"
    />

    <!-- MODAL -->
    <ModalContentQuestionnaire
      :is-open="isOpenQuestionnaire"
      @close="onCloseQuestionnaire"
    />

    <!-- MODAL -->
    <ModalSimpleInfo
      :is-open="isOpenModalSendToClientManager"
      :image="require('@/assets/images/image-question.svg')"
      title="Setelah dikirim ke Client Manager data pada panduan makan tidak dapat diubah kembali"
    >
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding="0 24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            variant="outlined"
            rounded="1000px"
            width="100%"
            :disabled="isLoadingSendToClientManager"
            @click="isOpenModalSendToClientManager = false"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            :disabled="isLoadingSendToClientManager"
            @click="handleSendToClientManager({
              id: selectedListClientMealPlanDetail?.id,
              clientManagerId: selectedListClientMealPlanDetail?.clientManagerId,
            })"
          >
            Konfirmasi
          </BaseButton>
        </c-flex>
      </template>
    </ModalSimpleInfo>

    <!-- MODAL -->
    <ModalSimpleInfo
      :is-open="isOpenModalSuccessSaveData2"
      :image="require('@/assets/images/image-question.svg')"
      title="Data [Profil Gizi/Rencana Makan] akan diduplikasike Panduan Makan Bulan ke-X"
    >
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding="0 24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            variant="outlined"
            rounded="1000px"
            width="100%"
            @click="isOpenModalSuccessSaveData2 = false"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="
              $router.push({
                ...$route,
                name: 'nutri.meal-plan.menu-recommendation.detail',
                params: {
                  bypass: 1,
                }
              })
            "
          >
            Konfirmasi
          </BaseButton>
        </c-flex>
      </template>
    </ModalSimpleInfo>

    <!-- MODAL CATATAN -->
    <ModalMealPlanNoteHistory 
      :is-open="isOpenModalNote"
      :meal-plan-id="selectedListClientMealPlanDetail?.id"
      :type="typeModalNote"
      @close="isOpenModalNote = false"
    />

    <DrawerSpecificNote />
  </c-box>
</template>

<script>
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import generalMixin from '@/utils/general-mixins'
import ModalContentQuestionnaire from '@/components/modals/modal-content-questionnaire.vue'
import { reqNutriMealPlan } from '@/requests/dietela-api/nutritionist/meal-plans'
import {
  reqMealPlans_sendToClientManager,
  reqNutritionist_mealPlans_getClientManager,
  reqNutritionist_mealPlans_getDetail,
  reqNutritionist_mealPlans_getOptionList,
} from '@/requests/dietela-api/nutritionist/other'
import { formatDateV2 } from '@/utils/format-date'
import BaseInputAutocomplete from '@/components/elements/base-input-autocomplete.vue'
import {
  CBox,
  CButton,
  CFlex,
  CGrid,
  CGridItem,
  CImage,
  CMenu,
  CMenuButton,
  CMenuItem,
  CMenuList,
  CText,
} from '@chakra-ui/vue'
import { reqMenuMealPlan_TriggerForBE_1, reqMenuMealPlan_TriggerForBE_2 } from '@/requests/dietela-api/other'
import BaseButton from '@/components/elements/base-button.vue'
import ModalSimpleInfo from '@/components/elements/modal-simple-info.vue'
import ModalDuplicateMealPlan from '@/components/meal-plan/_widgets/modal-duplicate-meal-plan.vue'
import ModalMealPlanNoteHistory from '@/components/meal-plan/_widgets/modal-meal-plan-note-history.vue'
import DownloadMealPlan from './_widgets/download-meal-plan.vue'
import EventBus from '@/utils/event-bus'
import { mapActions, mapGetters } from 'vuex'
import BaseEditor from '@/components/elements/base-editor.vue'
import DrawerSpecificNote from '@/components/meal-plan/_widgets/drawer-specific-note.vue'

export default {
  name: 'ManagementClientMealPlan',
  components: {
    BaseEditor,
    DownloadMealPlan,
    ModalDuplicateMealPlan,
    ModalSimpleInfo,
    BaseButton,
    CBox,
    CText,
    CFlex,
    CImage,
    CButton,
    CMenu,
    CMenuButton,
    CMenuList,
    CMenuItem,
    CGrid,
    CGridItem,
    BaseInputAutocomplete,
    BreadcrumbPath,
    ModalContentQuestionnaire,
    ModalMealPlanNoteHistory,
    DrawerSpecificNote,
  },
  mixins: [generalMixin],
  data() {
    return {
      isOpenQuestionnaire: false,
      client: {},
      readMore: false,
      optionListClientManager: [],
      totalMonth: 0,
      listClientMealPlan: {},
      selectedListClientMealPlanDetail: {},
      isEllipsis: false,
      intervalCheckIsEllipsis: null,
      program: null,
      isEdited: false,
      isLoadingSendToClientManager: false,
      isOpenModalSendToClientManager: false,
      isOpenModalSuccessSaveData2: false,
      isProgramConsultationBasic: false,
      triggerSaveMealPlan: null,
      note: '',
      isOpenModalNote: false,
      typeModalNote: '',
    }
  },
  computed: {
    ...mapGetters({
      editValidation: 'mealPlan/editValidation',
    }),
    isValidOnEdit() {
      if (this.$route.name.includes('edit') && this.editValidation?.routeName === this.$route.name) {
        return this.editValidation?.validation === 1 ? true : false
      }
      return true
    },
    breadcrumbPath() {
      return [
        { label: 'Beranda', href: { name: 'nutrisionist.managementClient' } },
        { label: 'Manajemen Klien', href: { name: 'nutrisionist.managementClient' } },
        { label: 'Detail Klien', href: { name: 'nutri.detail-client', params: { clientId: this.$route.params.clientId } } },
        { label: 'Detail Program', href: { name: 'nutri.program.detail', params: { clientId: this.$route.params.clientId, programId: this.$route.params.programId } } },
        { label: 'Panduan Makan', isCurrent: true },
      ]
    },
    month() {
      return parseInt(this.$route.params.month || '0')
    },
    optionListClientMealPlan() {
      if (this.totalMonth < 1) {
        return [
          {
            month: 1,
            isDisabled: false,
          },
        ]
      }
      return Array
        .from({ length: this.totalMonth }, (_, index) => index + 1)
        .map((idx) => {
          const getPrevMealPlan = this.listClientMealPlan.find((it) => it?.month == (idx - 1))
          const isDisabled = idx !== 1 ? (getPrevMealPlan && getPrevMealPlan?.status !== 'draft' ? false : true) : false
          return {
            month: idx,
            isDisabled: isDisabled,
          }
        })
    },
    watchRouteParamsMonthAndListClientMealPlan() {
      return {
        month: this.$route.params.month,
        listClientMealPlan: this.listClientMealPlan,
        triggerSaveMealPlan: this.triggerSaveMealPlan,
      }
    },
  },
  watch: {
    watchRouteParamsMonthAndListClientMealPlan: {
      async handler(v) {
        this.fetchPreview({
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: this.$route.params.month,
        })
        if (v.month && v.listClientMealPlan.length > 0) {
          // Trigger for BE 1
          await reqMenuMealPlan_TriggerForBE_1(this.$store.getters.axios, {
            clientId: this.$route.params.clientId,
            programId: this.$route.params.programId,
            month: this.$route.params.month,
          })

          this.updateSelectedListClientMealPlan()
          const findData = v.listClientMealPlan?.find((v2) => parseInt(v2?.month) === parseInt(v.month || '0'))
          if (!findData) {
            this.selectedListClientMealPlanDetail = {}
            return
          }
          const res3 = await reqNutritionist_mealPlans_getDetail(this.$store.getters.axios, {
            id: findData?.id,
          })
          this.selectedListClientMealPlanDetail = res3?.data?.data || {}
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    // Trigger for BE 1
    await reqMenuMealPlan_TriggerForBE_1(this.$store.getters.axios, {
      clientId: this.$route.params.clientId,
      programId: this.$route.params.programId,
      month: this.$route.params.month,
    })

    // Trigger for BE 2
    await reqMenuMealPlan_TriggerForBE_2(this.$store.getters.axios, {
      clientId: this.$route.params.clientId,
    })

    this.init()
    this.initMealPlanStatus()

    this.intervalCheckIsEllipsis = setInterval(() => {
      this.isEllipsis = this.$refs.ooa12?.$el?.scrollHeight > 30
    }, 100)

    EventBus.$on('TRIGGER_SAVE_MEAL_PLAN', val => {
      this.triggerSaveMealPlan = val
    })

    // const [res, res2] = await Promise.all(
    //   [
    //     reqNutritionist_mealPlans_getOptionList(this.$store.getters.axios, {
    //       programId: this.$route.params?.programId || '',
    //     }),
    //     reqNutritionist_mealPlans_getClientManager(this.$store.getters.axios),
    //   ],
    // )
    // this.optionListClientMealPlan = new Array(res?.data?.data?.month || 0).fill(0).map((_, idx) => idx + 1)
    // this.listClientMealPlan = res?.data?.data?.mealPlans || []
    //
    // this.optionListClientManager = res2?.data?.data?.map((v) => {
    //   return {
    //     id: v?.userId || '-',
    //     name: v?.user?.fullName || '-',
    //   }
    // }) || []
  },
  destroyed() {
    clearInterval(this.intervalCheckIsEllipsis)
  },
  methods: {
    ...mapActions({
      fetchPreview: 'mealPlan2/fetchPreview',
    }),
    async init() {
      try {
        const res = await reqNutriMealPlan.clientDetail(this.$store.getters.axios, {
          clientId: this.$route.params.clientId,
        })
        this.client = res.data?.data
      } catch (err) {
        this.$toast({
          title: 'Failed',
          description: err?.data?.message ? err?.data?.message : (err?.response?.data?.message ?? 'Ops! Something when wrong.'),
          status: 'error',
          duration: 5000,
          position: 'bottom-right',
          variant: 'subtle',
        })
      }
    },
    async initMealPlanStatus() {
      const [res, res2] = await Promise.all(
        [
          reqNutritionist_mealPlans_getOptionList(this.$store.getters.axios, {
            programId: this.$route.params?.programId || '',
          }),
          reqNutritionist_mealPlans_getClientManager(this.$store.getters.axios),
        ],
      )
      this.totalMonth = res?.data?.data?.month || 0
      this.listClientMealPlan = res?.data?.data?.mealPlans || []
      this.program = res?.data?.data?.activeProgram || null

      this.updateSelectedListClientMealPlan()

      this.optionListClientManager = res2?.data?.data?.map((v) => {
        return {
          id: v?.userId || '-',
          name: v?.user?.fullName || '-',
        }
      }) || []
    },
    formatDateV2,
    onOpenQuestionnaire() {
      this.isOpenQuestionnaire = true
    },
    onCloseQuestionnaire() {
      this.isOpenQuestionnaire = false
    },
    async handleSendToClientManager({ id, clientManagerId }) {
      try {
        this.isLoadingSendToClientManager = true
        await reqMealPlans_sendToClientManager(this.$store.getters.axios, {
          id,
          clientManagerId,
          note: this.note,
        })

        if (this.$route.name.includes('.edit')) {
          await new Promise(resolve => {
            setTimeout(() => {
              this.$router.replace({
                ...this.$route,
                name: this.$route.name.replace('.edit', '.detail'),
                bypass: 1,
              })
              resolve()
            }, 1000)
          })
        }
        window.location.reload()
      } catch (e) {
        this.$toast({
          title: 'Failed',
          description: e?.data?.message ? e?.data?.message : (e?.response?.data?.message ?? 'Ops! Something when wrong.'),
          status: 'error',
          duration: 5000,
          position: 'bottom-right',
          variant: 'subtle',
        })
      } finally {
        this.isLoadingSendToClientManager = false
        this.isOpenModalSendToClientManager = false
      }
    },
    async updateSelectedListClientMealPlan() {
      const findData = this.listClientMealPlan?.find((v2) => parseInt(v2?.month) === this.month)

      this.selectedListClientMealPlanDetail = findData || {}

      // const res3 = await reqNutritionist_mealPlans_getDetail(this.$store.getters.axios, {
      //   id: findData?.id,
      // })
      // this.selectedListClientMealPlanDetail = res3?.data?.data || {}

      const programStatus = this.program?.programStatus
      const mealPlanStatus = findData?.status || ''
      this.isProgramConsultationBasic = (this.program?.isKonsultasi && this.program?.programService?.toLowerCase() === 'basic') ? true : false

      if (programStatus === 'active' && (mealPlanStatus === 'draft' || mealPlanStatus === 'revisi')) {
        this.isEdited = true
      } else {
        this.isEdited = false
      }
    },
    onChangeMonth(option) {
      if (!option?.isDisabled && this.month != option?.month) {
        this.$router.push({
          name: this.$route.name.replace('.edit', '.detail'),
          params: {
            ...this.$route.params,
            month: option?.month,
          },
        }).catch(() => {})
      }
    },
    onOpenNoteList(type) {
      this.typeModalNote = type
      this.isOpenModalNote = true
    },
  },
}
</script>
